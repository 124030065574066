import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpService {

  API_URL = 'https://api.inhaabit.com/v2/';
  APP_ID = '/5ae283bc9278a33eba8c75a2';

  constructor(private http: HttpClient) {}

  post(request, data: any) {

    const url = this.API_URL + request + this.APP_ID;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(url, data); // JSON.stringify(data), httpOptions);
  }
}
