import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { TweenLite, Power3 } from 'gsap/all';
import { InformationComponent } from './information/information.component';
import { ChooserComponent } from '../chooser/chooser.component';
import { LoginComponent } from './login/login.component';
import { GreetingComponent } from './greeting/greeting.component';
import { AboutComponent } from './about/about.component';
import { TutorialComponent } from '../chooser/tutorial/tutorial.component';
import { AlertComponent } from '../alert/alert.component';
import { configuration } from '../chooser/connector/view3d/configuration.data';

@Component({
  selector: 'app-home',
  template: `
    <app-home-information (showHome)="show()"></app-home-information>
    <article class="page" #page>
      <div class="logo"></div>
      <div class="member" #member>Team member: {{memberName}}</div>
      <nav class="aside">
        <ul>
          <li class="spot" (click)=showLogin()>&#xe906;</li>
          <li class="spot" (click)=contact.show()>&#xe904;</li>
          <li class="spot" (click)=showInfo()>&#xe905;</li>
        </ul>
      </nav>
      <app-home-greeting (startBuilding)="showChooser()" (showAbout)="showAbout()"></app-home-greeting>
    </article>
    <app-tutorial></app-tutorial>
    <app-contact #contact></app-contact>
    <app-home-login #login (respond)="showMember($event)"></app-home-login>
    <app-home-about #about></app-home-about>
    <app-chooser (showChooser)="show()"></app-chooser>
  `,
  styles: [`
    .page {
      background-image: url("assets/img/ui/mags-bg.jpg");
      background-size: cover;
    }
    .logo {
      left: 74px;
      top: 50px;
      width: 250px;
      height: 62px;
      position: absolute;
      background-image: url("assets/img/ui/logo.png");
      background-size: contain;
    }
    .member { 
      left: 74px;
      top: 130px;
      position: absolute;
      font-size: 1rem;
      display: none;
    }
    .spot {
      font-family: "Icons";
      font-size: 28px;
      text-align: center;
      line-height: 54px;
      width: 69px;
      height: 54px;
      margin-bottom: 12px;
      background-color: #bcb4aa;
    }
    .spot:hover { background-color: #A8A096; }
    nav {
      position: absolute;
    }
    nav.aside {
      right: 0;
      display:none;
      top: 68px;
    }
    ul { list-style: none; overflow: hidden; }
    li {
      font-size: 1.2rem;
      cursor: pointer;
      outline: none;
    }
    .footer {
      position: absolute;
      width: 100%;
      bottom: 30px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.1rem;
    }
  `]
})
export class HomeComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('member') member: ElementRef;
  @ViewChild(GreetingComponent) greeting;
  @ViewChild(LoginComponent) login;
  @ViewChild(AlertComponent) alert;
  @ViewChild(InformationComponent) info;
  @ViewChild(ChooserComponent) chooser;
  @ViewChild(AboutComponent) about;
  @ViewChild(TutorialComponent) tutorial;

  memberName = '';

  ngOnInit() {

    window.addEventListener('beforeunload', (event) => {

      const message = 'Are you sure you want to exit?';

      if (typeof event === undefined) {
        event = window.event;
      }
      if (event) {
        event.returnValue = message;
      }
      return message;
    }, false );

    this.greeting.show();

    const salesData = {
      name: getCookie('name'),
      phone: getCookie('phone')
    };

    // console.log(`name: ${salesData.name}  phone: ${salesData.phone}`);

    this.showMember(salesData);
  }

  showLogin(): void {

    this.login.show();

  }

  showInfo(): void {

    this.info.show();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
  }

  showChooser(): void {

    this.chooser.show();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
  }

  showAbout(): void {

    this.about.run();
  }

  showMember(event): void {

    if (!event.name || !event.phone) { return; }

    this.member.nativeElement.style.display = 'block';
    this.memberName = event.name;

    configuration.salesName = event.name;
    configuration.salesPhone = event.phone;

    setCookie('name', event.name);
    setCookie('phone', event.phone);
  }

  show(): void {

    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0 });
  }
}

function setCookie(name, value) {

  document.cookie = `${name}=${encodeURIComponent(value)}; ; path=/`;
}

function getCookie(name) {

  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {

    const pattern = new RegExp(`^${name}=`);

    if (cookie.search(pattern) !== -1) {
      return decodeURIComponent(cookie.replace(pattern, ''));
    }
  }

  return null;
}
