import { Component, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { TweenLite, Power1, Power3 } from 'gsap/all';

@Component({
  selector: 'app-alert',
  template: `
    <article class="page" #page>
      <div class="box">
        <section class="message">
          <h1 class="title">{{message.title}}</h1>
          <p class="text">{{message.text}}</p>
          <div *ngIf="message.button" class="button" (click)="action()">{{message.button}}</div>
        </section>
        <div class="close" (click)=hide()>&#xe90b;</div>
      </div>
    </article>
  `,
  styles: [`
    .message {
      color: #333333;
      text-align: center;
    }
    .title {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .text {
      margin-top: 15px;
      font-size: 1rem;
    }
    .page {
      background-color: rgba(0, 0, 0, 0.4);
      display: none;
      z-index: 10;
      opacity: 0.5;
    }
    .box {
      position: absolute;
      width: 220px;
      top: 50%;
      left: 50%;
      padding: 40px;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 3%;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Icons";
      font-size: 12px;
      border-radius: 100%;
      color: #333333;
      cursor: pointer;
    }
    .close:hover { background-color: #FF252A; color: #fff; }
    .button {
      display: inline-block;
      background-color: #FF1400;
      border: none;
      color: #fff;
      padding: 10px 30px;
      margin-top: 25px;
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: normal;
      cursor: pointer;
    }
    .button:hover { background-color: #B80600; }
  `]
})

export class AlertComponent {

  @ViewChild('page') page: ElementRef;
  @Output() actionType = new EventEmitter<void>();

  message: any = { title: '', text: '', button: '' };

  public show(): void {

    this.page.nativeElement.style.display = 'block';
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, opacity: 1 });
  }

  hide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power1.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });
  }

  action() {

    this.hide();
    this.actionType.emit();
  }
}
