import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { TweenLite, Power1, Power2, Power3 } from 'gsap/all';

@Component({
  selector: 'app-home-greeting',
  template: `
    <!--article class="page" #page-->
      <section class="box" #box>
        <h1>Mags Sofa Builder</h1>
        <p class="align-center">Build your perfect Mags sofa. 
          Use drag & drop pieces to build your layout, and visualise your perfect sofa in realistic 3D.</p>
        <nav class="footer">
          <ul>
            <li class="button build" (click)=build()>Start Building</li>
          </ul>
        </nav>
      </section>
    <!--/article-->
  `,
  styles: [`
    .page {
      background-color: transparent;
      top: 0;
    }
    .box {
      position: absolute;
      width: 730px;
      height: 368px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 70px 80px;
      background-color: rgba(255, 255, 255, 0.9);
      color: #333333;
      border-radius: 8px;
      box-sizing: border-box;
      z-index: 10;
    }
    h1 {
      text-align: center;
      font-size: 62px;
      font-weight: bold;
    }
    p {
      margin-top: 15px;
      text-align: center;
      font-size: 19px;
    }
    .button {
      width: 210px;
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-weight: bold;
      box-sizing: border-box;
      text-align: center;
      border-radius: 60px;
    }
    .build {
      background-color: #333333;
    }
    .build:hover { background-color: #444444; }
    nav {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 70px;
      line-height: 0;
      text-align: center;
    }
    ul { list-style: none; overflow: hidden; display: inline-block; }
    li {
      cursor: pointer;
      outline: none;
    }
    .footer li {
      float: left;
    }
    .aside {display: none;}
  `]
})

export class GreetingComponent {

  @ViewChild('box') box: ElementRef;
  @Output() startBuilding = new EventEmitter<void>();
  @Output() showAbout = new EventEmitter<void>();

  public show(): void {

    TweenLite.from(this.box.nativeElement, 1.5, { ease: Power2.easeOut, opacity: 0 }).delay(1);
  }

  public build(): void {

    this.startBuilding.emit();
  }

  public about(): void {

    this.showAbout.emit();
  }
}
