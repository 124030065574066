import { Component, ViewChild, ViewChildren, QueryList, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { TweenLite, Power3, Expo } from 'gsap/all';
import { parts } from '../configurator2d/js/data';
import { Plan } from '../configurator2d/js/Plan';
import { Cfg3d } from './configurator3d/js/Cfg3d';
import { LEGS, ARMS } from './configurator3d/js/types';
import { ContactComponent } from '../../../contact/contact.component';
import { TutorialComponent } from '../../tutorial/tutorial.component';
import { Contact3dComponent } from './contact3d/contact3d.component';
import { EmailComponent } from './email/email.component';
import { QuoteComponent } from './quote/quote.component';
import { AlertComponent } from '../../../alert/alert.component';
import { configuration } from './configuration.data';
import { HttpService } from '../../../http.service';

enum Mode {
  Email,
  Quote
}

@Component({
  selector: 'app-view3d',
  template: `
    <article class="page" #page>
      <div class="flash" #flash></div>
      <span class="name"></span>
      <div class="arrow" (touchend)=hide() (click)=hide()>&#xe907;</div>
      <nav class="footer">
        <ul>
          <li class="btn" title="Take a photo" (touchend)=captureScene() (click)=captureScene()>&#xe902;</li>
          <!--li *ngIf="!forServer" class="btn" title="Customise arms & legs" (touchend)=chooseComponents($event) (click)=chooseComponents($event)>
            &#xe901;</li>
          <li-- *ngIf="!forServer" class="btn" title="Contact" (touchend)=showContact3d() (click)=showContact3d()>&#xe900;</li-->
        </ul>
      </nav>
    </article>
    <app-tutorial></app-tutorial>
    <app-contact></app-contact>
    <app-view3d-contact3d (actionType)="action($event)"></app-view3d-contact3d>
    <app-alert (actionType)="toEmailMode()"></app-alert>
    <app-alert></app-alert>
    <app-alert (actionType)="toQuoteMode()"></app-alert>
    <app-alert (actionType)="saveScene()"></app-alert>
    <app-alert (actionType)="openDocument()"></app-alert>
    <app-view3d-email (request)="request($event)"></app-view3d-email>
    <app-view3d-quote (generate)="generate()"></app-view3d-quote>
  `,
  styles: [`
    .page {
      left: 100%;
      background-color: #fff;
      z-index: 10;
    }
    .flash {
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0;
    }
    .arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      color: #A8A096;
      line-height: 50px;
      font-family: "Icons";
      font-size: 25px;
      left: 30px;
      top: 30px;
      border-radius: 4px;
      cursor: pointer;
    }
    .arrow:hover { color: #333333; font-size: 28px; }
    .btn {
      margin: 0 5px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      border-radius: 100%;
      font-family: "Icons";
      color: #A8A096;
      cursor: pointer;
    }
    .btn:hover { background-color: #bcb4aa; color: #000; }
    .footer {
      position: absolute;
      left: 50%;
      bottom: 40px;
      transform: translateX(-50%);
    }
    .footer ul { list-style: none; overflow: hidden; }
    .footer li {
      cursor: pointer;
      outline: none;
      float: left;
    }
  `],
  providers: [HttpService]
})
export class View3dComponent implements OnInit {
  @ViewChild('page') page: ElementRef;
  @ViewChild(ContactComponent) contact;
  @ViewChild(Contact3dComponent) contact3d;
  @ViewChildren(AlertComponent) alerts: QueryList<AlertComponent>;
  @ViewChild(TutorialComponent) tutorial;
  @ViewChild(EmailComponent) email;
  @ViewChild(QuoteComponent) quote;
  @ViewChild('flash') flash;
  @Output() showConnector = new EventEmitter<void>();

  promiseCfg3d: any;
  cfg3d: any;
  cfg2d: any;

  sequence: any[];
  forServer = false;
  mode: Mode;

  pdf: any;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.promiseCfg3d = new Cfg3d(this.page.nativeElement, {data: ''});
    this.promiseCfg3d.then((that) => { this.cfg3d = that; });
  }

  public show(): void {

    this.promiseCfg3d.then((that) => { that.setSequence(this.sequence, this.sequence[0].angle, LEGS.STRAIGHT, ARMS.NO); });

    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0, onComplete: () => {
      this.tutorial.run(1);
    }});
  }

  public chooseComponents(event): void {

    event.stopPropagation();

    this.promiseCfg3d.then((that) => { that.showChooserPanel(); });
  }

  public captureScene(): void {

    this.shine();

    if (this.forServer) {
      /*that.captureScene().then((image) => {
        console.log(image);
        this.email.image = image;
        this.email.show();
        this.forServer = false;
      });*/
      configuration.image3D = this.cfg3d.captureScene();

      switch (this.mode) {

        case Mode.Email:
          this.email.codes = configuration.partsCode;
          this.email.image = configuration.image3D;
          setTimeout(() => { this.email.show(); }, 400);
          break;

        case Mode.Quote:
          setTimeout(() => { this.sendData(); }, 400);
          break;

      }
      this.forServer = false;

    } else {

      setTimeout(() => {

        const alert = this.alerts.toArray()[3];
        alert.message.title = 'new photo';
        alert.message.text = 'Your photo will now be downloaded to your device.';
        alert.message.button = 'save photo';
        alert.show();
      }, 400);
    }

    this.cfg3d.startEdit();
  }

  public saveScene() {

    this.cfg3d.saveScene();
  }

  public sendData() {

    const alert = this.alerts.toArray()[1];
    alert.message.title = 'generate quote';
    alert.message.text = 'Please wait as we generate your quote.';
    alert.message.button = '';
    alert.show();

    const formData = new FormData();
    Object.entries(configuration).forEach(
      ([key, value]: any[]) => {
        if (Array.isArray(value)) {
          value.forEach(item => {
            formData.append(key + '[]', item);
          });
        } else {
          formData.append(key, value);
        }
      });

    // formData.set('powerMePositions', JSON.stringify(configuration.powerMePositions));

    this.httpService.post('quote', formData)
      .subscribe( (pdf: any) => {
        this.pdf = pdf;
        alert.hide();
        const openAlert = this.alerts.toArray()[4];
        openAlert.message.title = 'generate quote';
        openAlert.message.text = 'Your quote is ready.';
        openAlert.message.button = 'download';
        openAlert.show();

      }, error => {
        alert.hide();
        alert.message.title = 'sorry';
        alert.message.text = 'We encountered an issue.';
        alert.message.button = 'continue';
        alert.show();
        console.error(error);
      });
  }

  public showContact() {

    this.contact.codes = this.cfg3d.getSequence();

    this.cfg3d.hidePanels();

    // this.contact.message = 'Price: $' + this.cfg3d.getPrice() + ' (without wifi)\n\nDetails:\n' +  this.cfg3d.getDetails();
    this.contact.show();
  }

  public showQuote() {

    const plan = new Plan(document, this.cfg2d, parts);
    plan.getPromise().then((image) => {
      configuration.image2D = image;
    });

    configuration.dimensions = this.cfg2d.getRealSize().text;
    configuration.powerMePositions = this.cfg2d.getPMPoses();

    this.quote.show();
  }

  public showContact3d() {

    this.promiseCfg3d.then((that) => {

      that.hidePanels();

      this.contact3d.show();
    });
  }

  hide(): void {

    this.showConnector.emit();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '100%' });
  }

  public action(event) {

    const styles = this.cfg3d.getStyles();
    configuration.arms = this.cfg3d.getAmountOfArms();
    configuration.partsCode = this.cfg3d.getSequence();
    configuration.battensFinish = styles.battensFinish;
    configuration.frameFinish = styles.frameFinish;
    configuration.powerMeFinish = styles.powerMeFinish;
    configuration.price = this.cfg3d.getPrice();

    switch (event) {

      case 'emailConfig':
        const alert = this.alerts.toArray()[0];
        alert.message.title = 'email your configuration';
        alert.message.text = `Email a photo of your configuration and parts list. Get started by taking a photo.`;
        alert.message.button = 'take photo';
        alert.show();
        break;

      case 'getInTouch':
        this.showContact();
        break;

      case 'createQuote':
        this.showQuote();
        break;
    }
  }

  public request(event) {

    const alert = this.alerts.toArray()[1];

    switch (event) {

      case 'success':
        alert.message.title = 'configuration saved';
        alert.message.text = 'Your configuration has been emailed to you.';
        alert.message.button = 'continue';
        alert.show();
        break;

      case 'error':
        alert.message.title = 'Error';
        alert.message.text = 'Something went wrong.';
        alert.message.button = 'continue';
        alert.show();
        break;
    }
  }

  public openDocument() {

    window.open(this.pdf.url,'_blank');
    // wnd.location = pdf.url;
  }

  public toEmailMode() {

    this.cfg3d.stopEdit();

    this.forServer = true;
    this.mode = Mode.Email;
  }

  public toQuoteMode() {

    this.cfg3d.stopEdit();

    this.forServer = true;
    this.mode = Mode.Quote;
  }

  public generate() {

    const alert = this.alerts.toArray()[2];
    alert.message.title = 'generate quote';
    alert.message.text = 'Take a photo of your Aria to add it to your quote';
    alert.message.button = 'take photo';
    alert.show();
  }

  private shine(): void {

    this.flash.nativeElement.style.display = 'block';

    TweenLite.to(this.flash.nativeElement, 0.2, {
      ease: Expo.easeInOut,
      opacity: 0.7,
      onComplete: () => {
        this.flash.nativeElement.style.display = 'none';
      }
    });
  }

  public close() {

    this.cfg3d.startEdit();
  }
}
