import { Component, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { TweenLite, Power1, Power3 } from 'gsap/all';
import { configuration } from '../configuration.data';

@Component({
  selector: 'app-view3d-contact3d',
  template: `
    <div class="shadow" #shadow></div>
    <article class="page" #page>
      <div class="box">
        <h1>OPTIONS</h1>
        <ul>
          <li (click)=emailConfig()>email configuration</li>
          <li (click)=getInTouch()>contact us</li>
          <li *ngIf="phone" (click)=createQuote()>create quote</li>
        </ul>
        <div class="close" (click)=hide()>&#xe90b;</div>
      </div>
    </article>
  `,
  styles: [`
    .page {
      background-color: transparent;
      top: 100%;
      z-index: 10;
    }
    .box {
      position: absolute;
      width: 220px;
      top: 50%;
      left: 50%;
      padding: 40px;
      transform: translate(-50%, -50%);
      color: #333333;
      background-color: #fff;
      border-radius: 3%;
      font-size: 1.2rem;
      font-weight: bold;
    }
    h1 {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .shadow {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      display: none;
      z-index: 10;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Icons";
      font-size: 12px;
      border-radius: 100%;
      color: #333333;
      cursor: pointer;
    }
    .close:hover { background-color: #FF252A; color: #fff; }
    ul { 
      list-style: none; 
      overflow: hidden;
      margin-top: 20px;
    }
    li {
      height: 54px;
      cursor: pointer;
      border-top: solid 1px #bcb4aa;
      text-align: center;
      line-height: 54px;
    }
    li:hover { background-color: #bcb4aa; }
  `]
})

export class Contact3dComponent {
  @ViewChild('page') page: ElementRef;
  @ViewChild('shadow') shadow: ElementRef;
  @Output() actionType = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  phone: string;

  public show(): void {

    this.phone = configuration.salesPhone;
    this.shadow.nativeElement.style.display = 'block';
    this.page.nativeElement.style.opacity = 1;
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, top: 0 });
    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: .2});
  }

  hide(): void {

    this.close.emit();
    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power1.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.top = '100%'; }
    });
    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0, onComplete: () => {
      this.shadow.nativeElement.style.display = 'none';
    }});
  }

  public emailConfig() {

    this.hide();
    this.actionType.emit('emailConfig');
  }

  public getInTouch() {

    this.hide();
    this.actionType.emit('getInTouch');
  }

  public createQuote() {

    this.hide();
    this.actionType.emit('createQuote');
  }
}
