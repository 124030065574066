import { Component, ViewChild, ViewChildren, ElementRef, QueryList, AfterViewInit } from '@angular/core';
import { TweenLite, Power3 } from 'gsap/all';
import * as Swiper from '../../../../node_modules/swiper/dist/js/swiper.min.js';

@Component({
  selector: 'app-home-about',
  template: `
    <article class="page container" #container>
      <div class="swiper-container" #swiperContainer>
        <div class="swiper-wrapper">
          <article class="swiper-slide" #page *ngFor="let image of images; index as i" (click)=next()>
            <span class="text"></span>
          </article>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <nav class="footer" #panel>
          <div class="button" #button (click)=end()>let's get started</div>
      </nav>
    </article>
  `,
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements AfterViewInit {

  @ViewChildren('page') pages: QueryList<ElementRef>;
  @ViewChildren('dot') dots: QueryList<ElementRef>;
  @ViewChild('container') container: ElementRef;
  @ViewChild('button') button: ElementRef;
  @ViewChild('panel') panel: ElementRef;
  @ViewChild('swiperContainer') swiperContainer: ElementRef;

  swiper: any;

  images: string[] = ['about-1.jpg', 'about-2.jpg', 'about-3.jpg', 'about-4.jpg'];

  texts: string[] = [
    'Experience ARIA in Augmented Reality',
    'ARIA is a modular seating system',
    'You may wish to add connectivity<br>with PowerMe tables',
    'Build, visualise and experiment<br>with ARIA on site'
  ];

/*  config: any = {
    pagination: { el: '.swiper-pagination', clickable: true },
    mousewheel: true,
    direction: 'horizontal'
  };*/

  prevSlideNum = 0;

  ngAfterViewInit() {

    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      pagination: { el: '.swiper-pagination', clickable: true },
      mousewheel: true,
      direction: 'horizontal'
    });

    this.swiper.on('reachEnd', () => {
      this.button.nativeElement.style.opacity = 1;
    });

    this.swiper.on('transitionEnd', () => {
      if (this.swiper.isEnd && this.prevSlideNum === 3) { this.end(); }
      this.prevSlideNum = this.swiper.activeIndex;
    });

    this.init();
  }

  public next(): void {

    if (this.swiper.isEnd) {
      this.end();
      return;
    }

    this.swiper.slideNext();
  }

  public run(): void {

    TweenLite.to(this.container.nativeElement, 0.5, {ease: Power3.easeOut, top: 0});
  }

  public end(): void {

    TweenLite.to(this.container.nativeElement, 0.5, {
      ease: Power3.easeOut, top: '100%', onComplete: () => {
        this.init();
      }
    });
  }

  private init(): void {
    this.swiper.slideTo(0);
    this.container.nativeElement.style.top = '100%';
    this.button.nativeElement.style.opacity = 0;
    this.panel.nativeElement.style.opacity = 1;
    const pages = this.pages.toArray();
    for (let i = 0, count = this.pages.length; i < count; i++) {
      pages[i].nativeElement.style.backgroundImage = `url(assets/img/ui/${this.images[i]})`;
      pages[i].nativeElement.firstElementChild.innerHTML = this.texts[i];
    }
  }
}
