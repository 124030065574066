import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import {  AppComponent, HomeComponent, InformationComponent,
  ContactComponent, AlertComponent, LoginComponent, GreetingComponent, AboutComponent,
  ChooserComponent, TutorialComponent, ConnectorComponent, View3dComponent, Contact3dComponent,
  EmailComponent, QuoteComponent } from './index';

@NgModule({
  declarations: [
    AppComponent, HomeComponent, InformationComponent,
    ContactComponent, AlertComponent, LoginComponent, GreetingComponent, AboutComponent,
    ChooserComponent, TutorialComponent, ConnectorComponent, View3dComponent, Contact3dComponent,
    EmailComponent, QuoteComponent
  ],
  imports: [
    BrowserModule, AppRoutingModule, FormsModule, HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
