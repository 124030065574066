import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { TweenLite, Power3 } from 'gsap/all';

@Component({
  selector: 'app-home-information',
  template: `
    <article class="page" #page>
      <section class="purpose">
        <div class="logo"></div>
        <div class="text">
          <p>Our purpose is to bring lasting enjoyment to public places. 
            Since 1986, we have furnished more than 25,000 places in Australia and around the globe.</p>
          <p>Australia <a href="tel:1800027799" target="_blank">1800 027 799</a><br>
            United State <a href="tel:+18774066434" target="_blank">+1 877 406 6434</a><br>
            International <a href="tel:+61287748888" target="_blank">+61 2 8774 8888</a></p>
          <p>Email <a href="mailto:info@streetfurniture.com" target="_blank">info@streetfurniture.com</a><br>
            <a href="https://streetfurniture.com" target="_blank">streetfurniture.com</a></p>
        </div>
      </section>
      <div class="arrow" (click)=hide()>&#xe907;</div>
    </article>
  `,
  styles: [`
    a {color:white;}
    .page {
      left: 100%;
      background-image: url("assets/img/ui/purpose.jpg");
      background-size: cover;
      z-index: 10;
    }
    .arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      color: #D5CDC3;
      line-height: 50px;
      font-family: "Icons";
      font-size: 25px;
      left: 30px;
      top: 30px;
      border-radius: 4px;
      cursor: pointer;
    }
    .arrow:hover { color: #333333; font-size: 28px; }
    .purpose {
      width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .logo {
      flex-grow: 1;
      flex-shrink: 1;
      width: 30%;
      min-width: 250px;
      height: 250px;
      background: url("assets/img/ui/logo.png") 50% 50% no-repeat;
      background-size: 250px;
    }
    .text {
      width: 50%;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 250px;
      font-size: 2rem;
      text-align: left;
    }
    .text p {
      margin: 40px 0;
    }
  `]
})
export class InformationComponent {

  @ViewChild('page') page: ElementRef;
  @Output() showHome = new EventEmitter<void>();

  public show(): void {

    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0 });
  }

  hide(): void {

    this.showHome.emit();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '100%' });
  }
}
